import React from "react"
import Layout from "../components/common/layout"
import Meta from "../components/common/meta"
import PageTitle from "../components/pageTitle/pageTitle"

const companyPage = () => (
  <Layout page="company">
    <Meta
      title="company guide | 1moku co."
      description="hirofumi sugaを中心に庭園デザインをはじめ、庭師の視点でとらえた空間デザイン、ランドスケープデザインなどを手掛けている1moku co.の会社概要についてご紹介します。"
      url="https://www.1moku.co.jp/company/"
      type="article"
    />
    <PageTitle title="company guide" />
    <div className="company-container">
      <div className="conpany-container__head">
        <h1 className="company-container__title">
          <span className="en">company guide</span>
          <span className="ja">会社概要</span>
        </h1>
      </div>
      <div className="company-container__body">
        <div className="company-container__block block block--history">
          <table className="block__table">
            <tbody>
              <tr className="block__row">
                <th className="block__title" rowSpan="3">
                  <span className="en">history</span>
                  <span className="ja">沿革</span>
                </th>
                <td className="block__en">
                  <div className="block__year">1994.6</div>
                  <p className="block__text en">
                    Established as Master Gardener Ichimoku Isseki
                  </p>
                </td>
                <td className="block__ja">
                  <div className="block__year">1994.6</div>
                  <p className="block__text ja">庭匠 一木一石として創業</p>
                </td>
              </tr>
              <tr className="block__row">
                <td className="block__en">
                  <div className="block__year">2002</div>
                  <p className="block__text en">
                    Incorporated as YK Sakuteishu Ichimoku Isseki
                  </p>
                </td>
                <td className="block__ja">
                  <div className="block__year">2002</div>
                  <p className="block__text ja">有限会社 作庭衆 一木一石に</p>
                </td>
              </tr>
              <tr className="block__row">
                <td className="block__en">
                  <div className="block__year">2006.7</div>
                  <p className="block__text en">
                    Incorporated as KK 1moku (Ichimoku)
                  </p>
                </td>
                <td className="block__ja">
                  <div className="block__year">2006.7</div>
                  <p className="block__text ja">株式会社1moku設立</p>
                </td>
              </tr>
            </tbody>
          </table>
          {/* table */}
        </div>
        {/* block */}

        <div className="company-container__block block block--paid">
          <table className="block__table">
            <tbody>
              <tr className="block__row">
                <th className="block__title">
                  <span className="en">paid-up capital</span>
                  <span className="ja">資本金</span>
                </th>
                <td className="block__en">
                  <p className="block__text en">10 million yen</p>
                </td>
                <td className="block__ja">
                  <p className="block__text ja">1,000万円</p>
                </td>
              </tr>
            </tbody>
          </table>
          {/* table */}
        </div>
        {/* block */}

        <div className="company-container__block block block--ceo">
          <table className="block__table">
            <tbody>
              <tr className="block__row">
                <th className="block__title">
                  <span className="en">CEO</span>
                  <span className="ja">代表者</span>
                </th>
                <td className="block__en pc">
                  <p className="block__text en">Hirofumi Suga</p>
                </td>
                <td className="block__ja">
                  <p className="block__text en">Hirofumi Suga</p>
                </td>
              </tr>
            </tbody>
          </table>
          {/* table */}
        </div>
        {/* block */}

        <div className="company-container__block block block--business">
          <table className="block__table">
            <tbody>
              <tr className="block__row">
                <th className="block__title" rowSpan="7">
                  <span className="en">business activitie</span>
                  <span className="ja">事業内容</span>
                </th>
                <td className="block__en">
                  <p className="block__text en">
                    Garden design
                    <br className="pc" />
                    (CAD, 3D perspective renderings, presentations)
                  </p>
                </td>
                <td className="block__ja">
                  <p className="block__text ja">ランドスケープ設計・デザイン</p>
                </td>
              </tr>
              <tr className="block__row">
                <td className="block__en">
                  <p className="block__text en">
                    Garden landscaping and outdoor construction
                    <br className="pc" />
                    (private houses, condominiums, shops, etc.)
                  </p>
                </td>
                <td className="block__ja">
                  <p className="block__text ja">
                    庭園設計
                    <br />
                    <span className="block__s">
                      （CAD図面、 3Dパース 、プレゼンテーション）
                    </span>
                  </p>
                </td>
              </tr>
              <tr className="block__row">
                <td className="block__en">
                  <p className="block__text en">
                    Civil engineering
                    <br className="pc" />
                    (retaining walls, foundation improvement, etc.)
                  </p>
                </td>
                <td className="block__ja">
                  <p className="block__text ja">
                    造園・外構工事
                    <br />
                    <span className="block__s">
                      （大型ランドスケープ、商業空間、一般住宅、マンション、店舗
                      他）
                    </span>
                  </p>
                </td>
              </tr>
              <tr className="block__row">
                <td className="block__en">
                  <p className="block__text en">
                    Architectural design and construction
                  </p>
                </td>
                <td className="block__ja">
                  <p className="block__text ja">
                    土木工事
                    <br />
                    <span className="block__s">（よう壁、地盤改良 他）</span>
                  </p>
                </td>
              </tr>
              <tr className="block__row">
                <td className="block__en">
                  <p className="block__text en">
                    Space production
                    <br className="pc" />
                    (Installations, tearooms, stage design, etc.)
                  </p>
                </td>
                <td className="block__ja">
                  <p className="block__text ja">
                    空間演出
                    <br />
                    <span className="block__s">
                      （インスタレーション、茶会設営、舞台美術 他）
                    </span>
                  </p>
                </td>
              </tr>
              <tr className="block__row">
                <td className="block__en">
                  <p className="block__text en">
                    Management
                    <br className="pc" />
                    (kyo-sukashi branch thinning, sterilization, weeding, etc.)
                  </p>
                </td>
                <td className="block__ja">
                  <p className="block__text ja">管理</p>
                </td>
              </tr>
              <tr className="block__row">
                <td className="block__en">
                  <p className="block__text en">
                    Other services
                    <br className="pc" />
                    (tea gardens, suikinkutsu garden features)
                  </p>
                </td>
                <td className="block__ja">
                  <p className="block__text ja">
                    その他<span className="block__s">（茶庭、水琴窟）</span>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          {/* table */}
        </div>
        {/* block */}

        <div className="company-container__block block block--registration">
          <table className="block__table">
            <tbody>
              <tr className="block__row">
                <th className="block__title">
                  <span className="en">
                    second-class <br />
                    architect registration
                  </span>
                  <br className="sp" />
                  <span className="ja ml-0">設計事務所登録</span>
                </th>
                <td className="block__en">
                  <p className="block__text en">
                    1moku co. landscape architect
                  </p>
                </td>
                <td className="block__ja">
                  <p className="block__text ja">
                    株式会社1moku 二級建築士事務所
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          {/* table */}
        </div>
        {/* block */}

        <div className="company-container__block block block--addresses">
          <table className="block__table">
            <tbody>
              <tr className="block__row">
                <th className="block__title" rowSpan="2">
                  <span className="en">addresses registration</span>
                  <span className="ja">所在地</span>
                </th>
                <td className="block__ja">
                  <p className="block__text en">
                    <span className="block__office">head office</span>
                    <span className="block__address block__address--en">
                      2f (artless Inc.), 5-27-8 jingumae
                      <br />
                      shibuya-ku tokyo 150-0001 japan
                    </span>
                  </p>
                </td>
                <td className="block__en">
                  <p className="block__text en">
                    <span className="block__post-num en">〒150-0001</span>
                    <span className="block__address block__address--ja ja">
                      東京都渋谷区神宮前5-27-8 2F (artless Inc.)
                    </span>
                  </p>
                </td>
              </tr>
              <tr className="block__row block__row--tel">
                <td className="block__ja">
                  <p className="block__text en tel">
                    <span className="tel__head">email.</span>
                    <a className="tel__body" href="mailto:info@1moku.co.jp">
                      info@1moku.co.jp
                    </a>
                  </p>
                </td>
                <td className="block__en en tel">
                  <p className="block__text en tel">
                    <span className="tel__head">email.</span>
                    <a className="tel__body" href="mailto:info@1moku.co.jp">
                      info@1moku.co.jp
                    </a>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          {/* table */}
        </div>
        {/* block */}

        <div className="company-container__block block block--employee">
          <table className="block__table">
            <tbody>
              <tr className="block__row">
                <th className="block__title">
                  <span className="en">employees</span>
                  <span className="ja">従業員数</span>
                </th>
                <td className="block__ja">
                  <p className="block__text en">7</p>
                </td>
                <td className="block__en pc">
                  <p className="block__text en">7</p>
                </td>
              </tr>
            </tbody>
          </table>
          {/* table */}
        </div>
        {/* block */}
      </div>
    </div>
  </Layout>
)

export default companyPage
